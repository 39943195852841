<template>
  <div class="ma-10"><MainContent /></div>
</template>

<script>
import { MainContent } from "./components/InspirationEbook";

export default {
  components: {
    MainContent
  }
};
</script>
